import React from "react";
import {ContactUsWrapper, ContactUsContainer, ContactUsLeft, ContactUsRight, ContactUsContent, ContactUsContentHeading, ContactUsContentRow, ContactUsIconLink, ContactUsIconSpan,ContactUsRight2} from "./contactUs.style";

const ContactUs = ({eventTrack}) => {
    return (
        <ContactUsWrapper>

        <ContactUsContainer>
    
            <ContactUsLeft>
                <ContactUsContent>

                    <ContactUsContentHeading>
                        Beirut - Sales
                    </ContactUsContentHeading>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="https://maps.app.goo.gl/xFNtHVMXHaKcnxkF7" target="_blank">

                            <ContactUsIconSpan>
                                    <i className="fa-solid fa-address-card"></i>
                            </ContactUsIconSpan>      

                            Rawche Street, Fawaz building, Beirut, 1st Floor
                        </ContactUsIconLink>

                    </ContactUsContentRow>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="tel:+961 1 799 111" target="_self" onClick={eventTrack.bind(this)}>

                            <ContactUsIconSpan>
                                    <i className="fa-solid fa-phone"></i>
                            </ContactUsIconSpan>      

                            +961 1 799 111 - EXT: 128

                        </ContactUsIconLink>

                    </ContactUsContentRow>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="mailto:info@cdn.com.lb">

                            <ContactUsIconSpan>
                                <i className="fa-solid fa-envelope"></i>
                            </ContactUsIconSpan> 

                            info@cdn.com.lb

                        </ContactUsIconLink>
                        
                    </ContactUsContentRow>

                </ContactUsContent>
                {/* <ContactUsLine></ContactUsLine> this is a div as a line between the contact us*/}
            </ContactUsLeft>

            <ContactUsRight>
                <ContactUsContent>

                    <ContactUsContentHeading>
                        Beirut - After Sales
                    </ContactUsContentHeading>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="https://maps.app.goo.gl/eqGnjPCFY7iwZu1v5" target="_blank">

                            <ContactUsIconSpan>
                                    <i className="fa-solid fa-address-card"></i>
                            </ContactUsIconSpan>      

                            Abdalla Al Mashnook Street, Palm Building, 1st Floor

                        </ContactUsIconLink>

                    </ContactUsContentRow>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="tel:+961 1 799 111" target="_self" onClick={eventTrack.bind(this)}>

                            <ContactUsIconSpan>
                                    <i className="fa-solid fa-phone"></i>
                            </ContactUsIconSpan>      

                            +961 1 799 111 - EXT: 128

                        </ContactUsIconLink>

                    </ContactUsContentRow>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="mailto:info@cdn.com.lb">

                            <ContactUsIconSpan>
                                <i className="fa-solid fa-envelope"></i>
                            </ContactUsIconSpan> 

                            info@cdn.com.lb

                        </ContactUsIconLink>
                        
                    </ContactUsContentRow>

                </ContactUsContent>
                {/* <ContactUsLine></ContactUsLine> */}
            </ContactUsRight>

            <ContactUsRight2>
                <ContactUsContent Last={true}>

                    <ContactUsContentHeading>
                        North - Showroom
                    </ContactUsContentHeading>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="https://maps.app.goo.gl/Mynx21BYthWhSFrr8" target="_blank">

                            <ContactUsIconSpan>
                                    <i className="fa-solid fa-address-card"></i>
                            </ContactUsIconSpan>      

                            Kfarhazir Al Koura

                        </ContactUsIconLink>

                    </ContactUsContentRow>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="tel:+961 1 799 111" target="_self" onClick={eventTrack.bind(this)}>

                            <ContactUsIconSpan>
                                    <i className="fa-solid fa-phone"></i>
                            </ContactUsIconSpan>      

                            +961 1 799 111 - EXT: 500

                        </ContactUsIconLink>

                    </ContactUsContentRow>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="mailto:info@boschcomfort.com">

                            <ContactUsIconSpan>
                                <i className="fa-solid fa-envelope"></i>
                            </ContactUsIconSpan> 

                            info@boschcomfort.com

                        </ContactUsIconLink>
                        
                    </ContactUsContentRow>

                </ContactUsContent>
            </ContactUsRight2>
            
            <ContactUsRight2>
                <ContactUsContent Last={true}>

                    <ContactUsContentHeading>
                        South - Showroom
                    </ContactUsContentHeading>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="https://maps.app.goo.gl/3NzV4kV2ziqjG2qDA" target="_blank">

                            <ContactUsIconSpan>
                                    <i className="fa-solid fa-address-card"></i>
                            </ContactUsIconSpan>      

                            Saida

                        </ContactUsIconLink>

                    </ContactUsContentRow>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="tel:+961 1 799 111" target="_self" onClick={eventTrack.bind(this)}>

                            <ContactUsIconSpan>
                                    <i className="fa-solid fa-phone"></i>
                            </ContactUsIconSpan>      

                            +961 1 799 111 - EXT: 600

                        </ContactUsIconLink>

                    </ContactUsContentRow>

                    <ContactUsContentRow>

                        <ContactUsIconLink title="" href="mailto:info@boschcomfort.com">

                            <ContactUsIconSpan>
                                <i className="fa-solid fa-envelope"></i>
                            </ContactUsIconSpan> 

                            info@boschcomfort.com

                        </ContactUsIconLink>
                        
                    </ContactUsContentRow>

                </ContactUsContent>
            </ContactUsRight2>
            

        </ContactUsContainer>
        
    </ContactUsWrapper>
    )
}

export default ContactUs;
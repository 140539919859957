import styled from 'styled-components';
import { device } from '../../responsive-sizez';

export const ContactUsWrapper = styled.div`
    display:block;
    background: linear-gradient(to right, #008ecf, #00a8b0);
    border-color: #fff;
    color:#fff;
    font-family:  Helvetica, Arial, sans-serif;
    font-size:1.2rem;
    width:100%;
    height:20vh;
    margin-bottom:5vh;
    position:relative;

    

    @media ${device.laptopL_1440px} and (max-height: 550px) {
        height:50vh;
        padding-top:.2rem;
        font-size:1.3rem;
    }


    @media ${device.mobileL_425px} {
        height:50vh;
    }

    @media ${device.mobileL_425px} and (max-height: 667px) {
        height:50vh;
        padding-top:.2rem;
    }
`

export const ContactUsContainer = styled.div`
    width:80%;
  
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display:flex;
    justify-content:space-around;
    align-items:center;
    padding:3rem;

    
    @media ${device.mobileL_425px} {
        flex-direction: column;
        margin:1rem;
        width:100%;
        position:relative;
        font-size:1.6rem;
        align-items:flex-start;
        padding:1rem;
 
    }
`

export const ContactUsLeft = styled.div`
    line-height:2.5rem;
    position:relative;

    @media ${device.mobileL_425px} {
        margin-bottom:1rem;
        position:initial;
    }
    @media ${device.tablet_768px} and (max-height: 721px) {
        margin-left:2rem;
        position:initial;
    }
`

export const ContactUsLine = styled.div`
    width:1px;
    height:15vh;
    background:#fff;
    opacity:.5;
    position:absolute;
    right:-10px;
    Top:0;

    @media ${device.laptop_1024px} {
        right:0;
    }

    @media ${device.mobileL_425px} {
        height:1px;
        width:92%;
        position:absolute;
        right:initial;
        top:initial;
    }
`

export const ContactUsRight = styled.div`
    line-height:2.5rem;
    position:relative;

    @media ${device.mobileL_550px} {
        position:initial;
    }

    @media ${device.tablet_768px} and (max-height: 721px) {
        margin-left:2rem;
    }
`

export const ContactUsRight2 = styled.div`
    line-height:2.5rem;

    @media ${device.tablet_768px} and (max-height: 721px) {
        margin-left:2rem;
    }
`

export const  ContactUsContent = styled.div`
    display:flex;
    flex-direction: column;
    width:100%;
    margin-bottom:5px;
    margin-top:${(props) => (props.Last ? '10px' : 'none')};
`

export const ContactUsContentHeading = styled.h4`
    font-size:2rem;
    margin-bottom:1rem;
`

export const ContactUsContentRow = styled.div`

`
export const ContactUsIconLink = styled.a`
    text-decoration:none;
    background-color:transparent;
    cursor:pointer;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
    color:#fff;
`

export const ContactUsIconSpan = styled.span`
    cursor:pointer;
    margin-right:10px;
`

export const database = {
    
    Air_Conditioning : {
        imageSrc:"/assets/Air_Condition.jpg",
        headerText:"Air Conditioning",
        contentText:"Bosch inverter air condition are equipped with low ambient kit enabling excellent performance even at -15° C, giving you the best heating and cooling regardless of what the outdoor temperature is!",
        linkHref:"/assets/Air-condition-Lebanon.pdf",
        SectionHeaderText:"Air Conditioning And Ventilation",
        SectionHeaderSecondText:"Simply providing a pleasant environment for your home."
    },

    Ventilation : {
        imageSrc:"/assets/fan.jpg",
        headerText:"Ventilation",
        contentText:"Improve the air quality of your office and home with silent and stylish aspirators. Bosch fans can be installed on walls, ducts and ceilings.",
        linkHref:"/assets/Bosch_Fan-Lebanon.pdf"
    },

    Thermosiphon : {
        imageSrc:"/assets/Solar_Water_Heater.jpg",
        headerText:"Thermosiphon",
        contentText:"Bosch Hot water solutions carry the “Solar Keymark” certification and benefit from high-efficiency solar panels to guarantee maximum comfort and savings.",
        linkHref:"/assets/Thermosiphon_Launch-Lebanon.pdf",
        SectionHeaderText:"Hot Water Solutions",
        SectionHeaderSecondText:"Benefit from free solar energy - with pioneering solar technology from Bosch."
    },

    Collectors : {
        imageSrc:"/assets/Solar_Panel.jpg",
        headerText:"Collectors",
        contentText:"Our flat plate collectors are the ideal solution for those who simply want to get the most out of the sun's rays.",
        linkHref:"/assets/solar-collectors-Lebanon.pdf"
    },

    Gas_Water : {
        imageSrc:"/assets/gaz_water.jpg",
        headerText:"Instantaneous Gas Water Heater",
        contentText:"Enjoy the Ultra energy-efficient condensing technology offering endless supply of hot water that only an instantaneous tankless gas water heater can deliver.",
        linkHref:"/assets/Gas_Instantaneous_Water_Heater-Lebanon-s.pdf"
    },

    Large_Solar : {
        imageSrc:"/assets/Large_Solar_panel.jpg",
        headerText:"",
        contentText:"Large solar thermal plants in modular construction for hot water and heating can be used individually and cover the specific heat requirement completely or partially with economy-friendly solar energy. Individual configurations of large solar panel systems measuring up to approximately 10,000 sq. m",
        linkHref:"/assets/solar-collectors-Lebanon.pdf",
        SectionHeaderText:"Large solar thermal plants",
        SectionHeaderSecondText:"Benefit from free solar energy - with pioneering solar technology from Bosch."
    },

    Boilers : {
        imageSrc:"/assets/hot_water.jpg",
        headerText:"Hot water boilers",
        contentText:"The proven Unimat type series is available in various output sizes and generates heat and hot water highly efficiently.",
        linkHref:"/assets/o348906v272_BR_HotWaterBoilers_en.pdf",
        SectionHeaderText:"Steam and Hot Water Boilers",
        SectionHeaderSecondText:"Efficient and reliable energy systems from Bosch for industrial and commercial applications."
    },

    Steam : {
        imageSrc:"/assets/steam.jpg",
        headerText:"Steam boilers",
        contentText:"Future-proof steam generation with boiler systems from Bosch: 175 to 55,000 kg/h steam for industrial and commercial processes. Efficient, sustainable and reliable.",
        linkHref:"/assets/o426476v272_BR_SteamBoilers_en.pdf",
    },

    Combi : {
        imageSrc:"/assets/combi.jpg",
        headerText:"Combi Boiler",
        contentText:"Class 6000 W which is manufactured to provide comfort, efficiency and energy saving from A to Z will meet all expectations in a perfect way. You can choose either the 24, 28 and 35 kW capacity models to gain maximum efficiency according to your requirements and bring comfort to your life. In brief, Bosch Class 6000 W is an optimum solution responding to all of your expectations from a combi boiler.",
        linkHref:"/assets/Combi Gaz 6000 W brochure.pdf",
    },
}

